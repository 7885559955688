import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination, } from "react-table";
import {  useHistory } from "react-router-dom";
import GlobleFilter from "../../globalFilter/GlobleFilter";
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight,FaEllipsisV, FaSearch,FaAward } from "react-icons/fa";
import { BsFillGridFill } from "react-icons/bs";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {VideoPopup} from "../../popup/videoPopup";
import {ImagePopup} from "../../popup/imagePopup";

export const SearchResultTheme2 = () => {
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [data,setData]= useState([])
    const history = useHistory();
    const natStoneCount = useMemo(()=> localStorage.getItem("stoneCount"),[]);
    //-------------------------------------------------------------------------


    function handleClose(event, reason) {
        setCustomizeTable(false)
    }
   
    function ViewDetail(d){
        history.push({ pathname: "/details", state: data });
    }
   

    //-------------------------------------------------------------------------


    // const data = diamondsearch.value;
    // const MakePopup = ({ values }) =>  values && <PopUp props={values} /> ;
    const columns = useMemo(
        () => [
            {
                Header: " ",
                // accessor: "action",
                className: "text-left align-middle text-nowrap",
                accessor: (d) => (
                        <button
                            onClick={()=>{ViewDetail(d)}}
                            className="btn btn-secondary font-weight-bold"
                        >
                            Details
                        </button>
                    ),
            },
            {
                Header: "Media",
                accessor: (d) => (
                    <div className="d-flex">
                        <span>
                            {!d.AWS_Image ? (
                                <span style={{ width: "14px" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            ) : (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="tooltip">Image</Tooltip>
                                    }
                                >
                                    {/* <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primary mx-2 text-left"
                                        href={d.AWS_Image}
                                    > */}
                                        {/* <BsCardImage /> */}
                                    <ImagePopup Image={d.AWS_Image} Shape={d.C_Shape} />
                                    {/* </a> */}
                                </OverlayTrigger>

                            )}
                        </span>
                        <span>
                            {!d.VIDEO  ? (
                                <span style={{ width: "14px" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            ) : (
                                <>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="tooltip">Video</Tooltip>
                                        }
                                    >
                                        <VideoPopup ProductId={d.ProductId} WLDiamondType={d.diamond_type} CERTIFICATE_NO={d.CERTIFICATE_NO} Video={d.VIDEO}/>
                                    </OverlayTrigger>
                                </>
                            )}
                        </span>
                        <span>
                            {!d.CERTIFICATE_NO ? (
                                <span style={{ width: "14px" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            ) : (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Certificate
                                        </Tooltip>
                                    }
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primary mx-2 text-left"
                                        href={
                                            d.CERTIFICATE_LINK
                                        }
                                    >
                                        <FaAward />
                                    </a>
                                </OverlayTrigger>
                            )}
                        </span>
                    </div>
                ),
                className: "text-left align-middle text-nowrap",
                disableSortBy: true,
            },
            {
                Header: "Shape",
                accessor: "C_Shape",
                className: "text-left align-middle text-nowrap",
            },
            {
                Header: "Color",
                // accessor: "C_Color",
                sortMethod: (a, b) => a.localeCompare(b),
                accessor: (d) => (
                    <>
                        <span>
                            {d.FANCY_COLOR ? (
                                <>
                                    {d.FANCY_INTENSITY} {d.FANCY_OVERTONE} {d.FANCY_COLOR}
                                </>
                            ) : (
                                d.COLOR
                            )}
                        </span>
                    </>
                ),
                minwidth: 50,
                className: "text-left align-middle text-nowrap",
                Cell: (row) => {
                    return (
                        <>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip">{row.value}</Tooltip>
                                }
                            >
                                <span
                                    className="d-inline-block text-truncate text-dark"
                                    style={{ maxWidth: "80px" }}
                                >
                                    {row.value}
                                </span>
                            </OverlayTrigger>
                        </>
                    );
                },
            },
            {
                Header: "Carat",
                className: "text-left align-middle text-nowrap",
                accessor: (d) => Number(d.C_Weight).toFixed(2),
                sortMethod: (a, b) => Number(a) - Number(b),
                Cell: (row) => {
                    return <span>{row.value}</span>;
                },
            },

            {
                Header: "Clarity",
                accessor: "C_Clarity",
                className: "text-left align-middle text-nowrap",
            },
            {
                Header: "Cut",
                accessor: "C_Cut",
                className: "text-left align-middle text-nowrap",
            },
            {
                Header: "Pol.",
                accessor: "C_Polish",
                className: "text-left align-middle text-nowrap",
            },
            {
                Header: "Symm.",
                accessor: "C_Symmetry",
                className: "text-left align-middle text-nowrap",
            },
            {
                Header: "Flou.",
                accessor: "C_Fluorescence",
                className: "text-left align-middle text-nowrap",
            },
            {
                Header: "Lab",
                accessor: "Lab",
                className: "text-left align-middle text-nowrap",
            },
            {
                Header:  `$ /CT`,
                className: "text-left pr-5 align-middle total text-nowrap",
                accessor: d => Number(d.MarkUpRate).toFixed(2),
                sortMethod: (a, b) => Number(a)-Number(b),
                Cell: (row) => {
                    return  <span className="d-flex"> {row.value}</span>;
                  },
            },
            {
                Header: "Total price",
                className: "text-left pr-5 text-nowrap align-middle total",
                accessor: d => Number(d.MarkUpPrice).toFixed(2),
                sortMethod: (a, b) => Number(a)-Number(b),
                Cell: (row) => {
                    return  <span className="d-flex">${row.value}</span>;
                  },
            },
            {
                Header: "Meas.",
                // accessor: "C_Length",
                accessor: (d) => (<span className="d-flex">{Number(d.C_Length).toFixed(2)} x {Number(d.C_Width).toFixed(2)} x {Number(d.C_Depth).toFixed(2)} </span>),
                className: "text-left align-middle text-nowrap",
            },
            {
                Header: "Table %",
                accessor: "C_TableP",
                className: "text-left align-middle text-nowrap",
                Cell: (row) => {
                    return <>{row.value}% </>;
                },
            },
            {
                Header: "Deapth %",
                accessor: "C_DefthP",
                className: "text-left align-middle text-nowrap",
                Cell: (row) => {
                    return <>{row.value}% </>;
                },
            },
            {
                Header: "Ratio",
                accessor: "Ratio",
                className: "text-left align-middle text-nowrap",
                Cell: (row) => {
                    return <>{row.value} </>;
                },
            },
            {
                Header: "Deliver In",
                accessor: "ShippingDays",
                className: "text-left align-middle text-nowrap",
                Cell: (row) => {
                    return <>{row.value} Days </>;
                },
            },
        ], []
    );

    const natGrid = () => {
        history.push({ pathname: "/grid", state: data });
    };

    const backBtn = () => {
        history.push({ pathname: "/searchdiamond" });
    };

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );


    return (
        <>
            <div className={`container theme2  ${
                   data.length >0 && data[0].diamond_type === "N" ? "natTab" : "labTab"
                }`}> 
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header py-3 mb-3 align-items-center">
                        <div className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-primary">
                                {natStoneCount} Stones Found
                            </span>
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                            <button
                                className="btn btn-primary btn-sm btn-square mr-3 text-nowrap"
                                onClick={natGrid}
                            >
                                <BsFillGridFill /> Grid
                            </button>
                            <button
                                className="btn btn-warning btn-sm btn-square mr-3 text-nowrap"
                                onClick={backBtn}
                            >
                                <FaSearch /> Go To Search
                            </button>
                            <GlobleFilter
                                filter={globalFilter}
                                setFilter={setGlobalFilter}
                            />
                            <div className="search_result_dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle size="sm" variant="clear">
                                        <FaEllipsisV />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() =>
                                                setCustomizeTable(true)
                                            }
                                        >
                                            Customize Table
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Modal
                                    size="sm"
                                    show={CustomizeTable}
                                    onHide={() => setCustomizeTable(false)}
                                    aria-labelledby="Customize-table"
                                >
                                    <Modal.Header
                                        className="px-5 py-3"
                                        closeButton
                                    >
                                        <Modal.Title id="Customize-table">
                                            Customize Columns
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                        className="p-2"
                                        style={{
                                            height: "400px",
                                            overflow: "auto",
                                        }}
                                    >
                                        <div className="p-3">
                                            <label className="checkbox my-2 border p-2">
                                                <input
                                                    type="checkbox"
                                                    {...getToggleHideAllColumnsProps()}
                                                />
                                                <span className="mr-2"></span>
                                                Show All
                                            </label>
                                            {allColumns.map((column) => (
                                                <div key={column.id}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input
                                                            type="checkbox"
                                                            {...column.getToggleHiddenProps()}
                                                        />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="px-5 py-3">
                                        <button
                                            type="button"
                                            className="btn btn-light mr-2 text-nowrap"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                        {/* <button
                                            type="button"
                                            className="btn btn-primary mr-2 text-nowrap"
                                            onClick={handleClose}
                                        >
                                            Save Changes
                                        </button> */}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-7 pb-0 mt-n3">
                        <div className="table-responsive w-100">
                            <table
                                className="table table-hover custom_datatable"
                                {...getTableProps()}
                            >
                                <thead className="custom_datatable_head">
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="text-center align-middle text-nowrap"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                        <span className="text-secondary">
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <FaSortDown />
                                                                ) : (
                                                                    <FaSortUp />
                                                                )
                                                            ) : (
                                                                ""
                                                            )}
                                                        </span>
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <>
                                                <tr
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                >
                                                    {row.cells.map(
                                                        (cell, i) => {
                                                            return (
                                                                <td
                                                                    className="text-left align-middle"
                                                                    {...cell.getCellProps(
                                                                        {
                                                                            className:
                                                                                cell
                                                                                    .column
                                                                                    .className,
                                                                        }
                                                                    )}
                                                                    key={i}
                                                                >
                                                                    {cell.render(
                                                                        "Cell"
                                                                    )}
                                                                </td>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer border-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="table_page_length">
                                Page {pageIndex + 1} of {pageOptions.length}{" "}
                            </span>
                            <div>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    <FaAngleDoubleLeft />
                                </button>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    <FaAngleLeft />
                                </button>
                                <span className="font-weight-bold text-muted">
                                    {"  "}
                                    <input
                                        className="form-control d-inline-block form-control-sm"
                                        type="number"
                                        value={pageIndex + 1}
                                        onChange={(e) => {
                                            const pageNumber = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(pageNumber);
                                        }}
                                        style={{ maxWidth: "70px" }}
                                    />
                                    {"  "}of{"  "} {pageOptions.length}
                                    {"  "}
                                </span>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    <FaAngleRight />
                                </button>
                                <button
                                    className="btn btn-sm btn-light"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    <FaAngleDoubleRight />
                                </button>
                            </div>
                            <span className="d-flex align-items-center">
                                <span className="text-nowrap page_size">
                                    Rows Per page:{" "}
                                </span>
                                <select
                                    className="form-select ml-2 custom-select py-1"
                                    value={pageSize}
                                    onChange={(e) =>
                                        setPageSize(Number(e.target.value))
                                    }
                                >
                                    {[10, 25, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};
