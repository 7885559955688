/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHistory } from "react-router-dom";
import { FaVideo } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoIframe from "./VideoIframe";
import { SendInquiry } from "../../../popup/sendInquiry";
// import DiamondContext from "../../searchDiamond/SearchDiamondContext";
export const GridCardContentSimilar = (props) => {
    const history = useHistory();
    const [showVideo, setShowVideo] = React.useState(false);

    function handleClose(event, reason) {
        setShowVideo(false)
    }

    function openVideoModal() {
        setShowVideo(true)
    }

    function handelDetails(data){
        history.push({  pathname: '/details', state:{
            StockID:data.STOCK_ID,
            diamond_type:data.COLOR?data.diamond_type:data.diamond_type + "F"
        }})
       
        window.scrollTo(0, 0)
    }

    // const  certificate_link =''
    function certificateLink(){
        if (props.CERTIFICATE_LINK){
            const certificate_link = props.CERTIFICATE_LINK
            return certificate_link
        }
    }
    function Currency(){
        if (props.themeData && props.themeData.api_currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }  else if (props.themeData && props.themeData.api_currency === "SGD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "THB"){
            const  currencyIcon = "฿"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "MYR"){
            const  currencyIcon = "RM"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "BND"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "DKK"){
            const  currencyIcon = "kr."
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "SEK"){
            const  currencyIcon = "kr"
            return currencyIcon
        }
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>
            <div
                className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5  ${
                    props.diamond_type === "N" ? "natTab" : "labTab"
                }`}
                key={props.STOCK_ID}
                id={props.STOCK_ID}
            >
                <div className="row m-0 card gridData">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200" style={{height:"220px"}}>
                        {props.IMAGE ? (
                          
                            <LazyLoadImage
                                className="img-fluid cursor-pointer"
                                onClick={() => handelDetails(props)}
                                src={ props.IMAGE}
                                alt={props.SHAPE}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${props.SHAPE}-no.webp`
                                        )
                                    }}
                                effect="blur" 
                            />
                        ) : (
                            <img
                                className="img-fluid cursor-pointer"
                                onClick={() => handelDetails(props)}
                                alt="shape"
                                src={toAbsoluteUrl(
                                    `/media/shape/${props.SHAPE}-no.webp`
                                )}
                            />
                            
                        )}
                    </div>
                    <div className="col-12 my-3 d-flex justify-content-between align-items-center">
                    <a
                            id={props.CERTIFICATE_NO}
                            href={certificateLink()}
                            target="_blank"
                            rel="noreferrer"
                            className="font-size-sm text-primary"
                        >
                            {props.LAB}
                        </a>
                       
                        <span className="font-size-sm text-primary">
                        {props.VIDEO ? (
                          <>
                                <FaVideo className="font-size-sm text-primary cursor-pointer"  onClick={() => openVideoModal(props)} />
                                <VideoIframe
                                    showVideo={showVideo}
                                    handleClose={handleClose}
                                    src={props.VIDEO }
                                />
                          </>
                        ) : (
                             <span id={props.VIDEO} > </span>
                        )}
                        </span>
                    </div>
                    <div className="col-12 text-truncate">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {props.CARAT && props.CARAT.toFixed(2)} {props.SHAPE}{" "}
                                    {(props.FANCY_COLOR) ? (<>{props.FANCY_INTENSITY} {props.FANCY_OVERTONE} {props.FANCY_COLOR}</>): props.COLOR}{" "}
                                    {props.CLARITY} {props.CUT}{" "}
                                    {props.POLISH} {props.SYMMETRY} {props.FLUORESCENCE} <br />
                                    M: {props.LENGTH && props.LENGTH.toFixed(2)} x {props.WIDTH && props.WIDTH.toFixed(2)} x {props.DEPTH && props.DEPTH.toFixed(2)} T:{props.TABLE_PER && props.TABLE_PER.toFixed(2)}%  D:{props.DEPTH_PER && props.DEPTH_PER.toFixed(2)}%  R:{props.RATIO}
                                </Tooltip>
                            }
                        >
                            <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                <span className="pr-2">
                                    {props.CARAT && props.CARAT.toFixed(2)}
                                </span>
                                <span className="pr-2">{props.SHAPE}</span>
                                { (props.FANCY_COLOR) ?
                                    (
                                        <>
                                             <span className="pr-2">{props.FANCY_INTENSITY}</span>
                                             <span className="pr-2">{props.FANCY_OVERTONE}</span>
                                             <span className="pr-2">{props.FANCY_COLOR}</span>
                                        </>
                                    ) : (
                                        <>
                                             <span className="pr-2">{props.COLOR}</span>
                                        </>
                                    )
                                }
                                <span className="pr-2">{props.CLARITY}</span>
                                <span className="pr-2">{props.CUT}</span>
                                <span className="pr-2">{props.POLISH}</span>
                                <span className="pr-2">{props.SYMMETRY}</span>
                                <span className="pr-2">{props.FLUORESCENCE}</span>
                            </span>
                            
                        </OverlayTrigger>
                        <hr className="w-75 m-1 px-5 pt-2 pb-0" />
                    </div>

                    {props.themeData && !props.themeData.ifm_no_price?<div className="col-12 text-center">
                        <div className="font-size-h1 font-weight-bolder text-primary">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="currency_t">
                                    {Currency()}/CT {numberWithCommas(props.PRICE_PER_CTS)}
                                </Tooltip>
                            }
                        >
                           <span> {Currency()}{numberWithCommas(props.TOTAL_PRICE)}</span>
                        </ OverlayTrigger>
                        </div>
                        <hr className="w-75 m-1 px-5 py-2" />
                    </div>:""}
                    <div className="col-12 d-flex align-items-center justify-content-between mb-5">
                        <button className="btn btn-secondary font-weight-bold" onClick={() => handelDetails(props)}>
                            {props.language.CCMODE_THEME1_GRID_DETAILBTN}
                        </button>
                      <SendInquiry diamond_details={props} ipData={props.ipData} language={props.language}/>
                    </div>
                </div>
            </div>
            
        </>
    );
};
