import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import { ImagePopup } from "../../popup/imagePopup";
import { VideoPopup } from "../../popup/videoPopup";

import { FaAward } from "react-icons/fa";

function total(x) {
    let total = parseFloat(x.O_Rate * x.CARAT).toFixed(2);
    return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const searchResultColumn = (user,language) => {
    let searchcolumns = [
        {
            Header: language.CCMODE_THEME1_LIST_SHAPE,
            accessor: "SHAPE",
            className: "text-center align-middle  text-nowrap",
            Cell: (row) => {
                return (
                    <>
                        <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">{row.value}</Tooltip>}
                    >
                        <SVG
                            width="25px"
                            height="25px"
                            src={toAbsoluteUrl(
                                `/media/shape/svg_shape/${row.value.toLowerCase()}.svg`
                            )}
                        />
                    </OverlayTrigger>
                    </>
                );
            },
        },
        {
            Header: language.CCMODE_THEME1_LIST_MEDIA,
            accessor: (d) => (
                <div className="d-flex">
                    <span>
                        {!d.IMAGE ? (
                            <span style={{ width: "14px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : (
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip">Image</Tooltip>
                                }
                            >
                                {/* <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={d.IMAGE}
                                > */}
                                    {/* <BsCardImage /> */}
                                <ImagePopup Image={d.IMAGE} Shape={d.C_Shape} />
                                {/* </a> */}
                            </OverlayTrigger>
    
                        )}
                    </span>
                    <span>
                        {!d.VIDEO ? (
                            <span style={{ width: "14px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : (
                            <>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="tooltip">Video</Tooltip>
                                    }
                                >
                                <VideoPopup ProductId={d.STOCK_ID} WLDiamondType={d.diamond_type} CERTIFICATE_NO={d.CERTIFICATE_NO} Video={d.VIDEO}/>
                                </OverlayTrigger>
                            </>
                        )}
                    </span>
                    <span>
                        {!d.CERTIFICATE_NO ? (
                            <span style={{ width: "14px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : (
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Certificate
                                    </Tooltip>
                                }
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        d.CERTIFICATE_LINK
                                    }
                                >
                                    <FaAward />
                                </a>
                            </OverlayTrigger>
                        )}
                    </span>
                </div>
            ),
            className: "text-left align-middle text-nowrap",
            disableSortBy: true,
        },
        
        {
            Header: language.CCMODE_THEME1_LIST_COLOR,
            // accessor: "C_Color",
            sortMethod: (a, b) => a.localeCompare(b),
            accessor: (d) => (
                <>
                    <span>
                        {d.FANCY_COLOR ? (
                            <>
                                {d.FANCY_INTENSITY} {d.FANCY_OVERTONE} {d.FANCY_COLOR}
                            </>
                        ) : (
                            d.COLOR
                        )}
                    </span>
                </>
            ),
            minwidth: 50,
            className: "text-center align-middle  text-nowrap",
            Cell: (row) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="tooltip">{row.value}</Tooltip>
                            }
                        >
                            <span
                                className="d-inline-block text-truncate text-dark"
                                style={{ maxWidth: "80px" }}
                            >
                                {row.value}
                            </span>
                        </OverlayTrigger>
                    </>
                );
            },
        },
        {
            Header: language.CCMODE_THEME1_LIST_CARAT,
            className: "text-center align-middle  text-nowrap",
            accessor: (d) => Number(d.CARAT).toFixed(2),
            sortMethod: (a, b) => Number(a) - Number(b),
            Cell: (row) => {
                return <span>{row.value}</span>;
            },
        },
    
        {
            Header: language.CCMODE_THEME1_LIST_CLARITY,
            accessor: "CLARITY",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_CUT,
            accessor: "CUT",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_POLISH,
            accessor: "POLISH",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_SYMMETRY,
            accessor: "SYMMETRY",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_FLUORESCENCE,
            accessor: "FLUORESCENCE",
            className: "text-center align-middle text-nowrap",
        },
        
            // {
            //     Header:  `${user && user.api_currency} /CT`,
            //     id:"dollar_carat",
            //     className: "text-right align-middle text-nowrap",
            //     accessor: d => Number(d.PRICE_PER_CTS).toFixed(2),
            //     sortMethod: (a, b) => Number(a)-Number(b),
            //     Cell: (row) => {
            //         return  (
            //             <>
            //                 {user && !user.ifm_no_price?<span className="d-inline">{user && user.api_currency === "USD" ? "$"  :  user && user.api_currency === "INR" ? "₹" : user && user.api_currency === "HKD" ? "$" : user && user.api_currency === "AUD" ? "$" : user && user.api_currency === "EUR" ? "€" : user && user.api_currency === "CAD" ? "$" : user && user.api_currency === "CNY" ? "¥" : user && user.api_currency === "GBP" ? "£" : user && user.api_currency === "NZD" ? "$" : user && user.api_currency === "JPY" ? "¥" : user && user.api_currency === "CHF" ? "₣" : user && user.api_currency === "SGD" ? "$" : user && user.api_currency === "THB" ? "฿" : user && user.api_currency === "MYR" ? "RM" : user && user.api_currency === "BND" ? "$" : user && user.api_currency === "DKK" ? "Kr." : user && user.api_currency === "SEK" ? "kr" : ''  } {numberWithCommas(parseFloat(row.value))}</span>:""}
            //             </>
            //         );
            //     },
            // },
            {
            Header: language.CCMODE_THEME1_LIST_TOTALPRICE,
            className: "text-right align-middle text-nowrap",
            id:"total_price",
            accessor: d => Number(d.TOTAL_PRICE).toFixed(2),
            sortMethod: (a, b) => Number(a)-Number(b),
            Cell: (row) => {
                return  (
                    <>
                        {user && !user.ifm_no_price?<span className="d-inline">{user && user.api_currency === "USD" ? "$"  :  user && user.api_currency === "INR" ? "₹" : user && user.api_currency === "HKD" ? "$" : user && user.api_currency === "AUD" ? "$" : user && user.api_currency === "EUR" ? "€" : user && user.api_currency === "CAD" ? "$" : user && user.api_currency === "CNY" ? "¥" : user && user.api_currency === "GBP" ? "£" : user && user.api_currency === "NZD" ? "$" : user && user.api_currency === "JPY" ? "¥" : user && user.api_currency === "CHF" ? "₣" : user && user.api_currency === "SGD" ? "$" : user && user.api_currency === "THB" ? "฿" : user && user.api_currency === "MYR" ? "RM" : user && user.api_currency === "BND" ? "$" : user && user.api_currency === "DKK" ? "Kr." : user && user.api_currency === "SEK" ? "kr" : ''  }{numberWithCommas(parseFloat(row.value))}</span>:""}
                    </>
                );
              },
        },
        {
            Header: language.CCMODE_THEME1_LIST_LAB,
            accessor: "LAB",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_DIAMOND_DETAILS_MEASUREMENT,
            accessor: (d) => (
                <>
                    <span className="d-flex">{d.LENGTH ? parseFloat(d.LENGTH).toFixed(2) : null} x {d.WIDTH ? parseFloat(d.WIDTH).toFixed(2):null} x {d.DEPTH?parseFloat(d.DEPTH).toFixed(2):null} </span>
                </>
            ),
            className: "text-center align-middle text-nowrap",
        },
        // {
        //     Header: language.CCMODE_THEME1_LIST_MEASUREMENT,
        //     // accessor: "C_Length",
        //     accessor: (d) => (<span className="d-flex">{Number(d.LENGTH).toFixed(2)} x {Number(d.WIDTH).toFixed(2)} x {Number(d.DEPTH).toFixed(2)} </span>),
        //     className: "text-center align-middle text-nowrap",
        // },
        // {
        //     Header: `${language.CCMODE_THEME1__LIST_TABLE} %`,
        //     accessor: "TABLE_PER",
        //     className: "text-center align-middle text-nowrap",
        //     Cell: (row) => {
        //         return <>{row.value}% </>;
        //     },
        // },
        // {
        //     Header: `${language.CCMODE_THEME1__LIST_DEPTH} %`,
        //     accessor: "DEPTH_PER",
        //     className: "text-center align-middle text-nowrap",
        //     Cell: (row) => {
        //         return <>{row.value}% </>;
        //     },
        // },
        // {
        //     Header: language.CCMODE_THEME1__LIST_RATIO,
        //     accessor: "RATIO",
        //     className: "text-center align-middle text-nowrap",
        //     Cell: (row) => {
        //         return <>{row.value} </>;
        //     },
        // },
        
    ]
    if(user && user.ifm_no_price){
        searchcolumns = searchcolumns.filter((val,index) => index !== 9 && index !== 10)
    }
    return searchcolumns
};
